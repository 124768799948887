<template lang="pug">
.tag(@click.stop)
  span.label
    slot(name="tag-label" :tag="tag" :label="tag[label]") {{ tag[label] }}
  ui-icon(
    clickable
    :size="14"
    :icon="UiIconNames.Icon_Close"
    @click.stop="$emit('close')"
  )
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UiIcon from "~/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

import type { PropType } from "vue";
import type { TagI } from "@/stores/manuals/ManualsInterface";

export default defineComponent({
  name: "ReadableTag",
  components: {
    UiIcon,
  },
  emits: [
    'close',
  ],
  props: {
    tag: {
      type: Object as PropType<TagI>,
      default: () => ({}),
    },
    label: {
      type: String,
      default: "title",
    },
  },
  setup() {
    return {
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/elements/tag';
</style>
