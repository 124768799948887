<template lang="pug">
ui-select(
  track-by="id"
  placeholder="Выберите валюту"
  label="Валюта"
  :teleported="false"
  :disabled="disabled"
  :model-value="modelValue"
  :options="currencies"
  @update:modelValue="selectCurrency"
)
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { storeToRefs } from "pinia";

import UiSelect from "@/components/ui/select/select/UiSelect.vue";
import useManualsStore from "@/stores/manuals/useManualsStore";

export default defineComponent({
  name: "CurrencySelector",
  components: {
    UiSelect,
  },
  emits: [
    'update:modelValue',
    'setCurrency',
  ],
  props: {
    modelValue: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const manualsStore = useManualsStore()
    const { currencies } = storeToRefs(manualsStore)

    function selectCurrency(id: number) {
      context.emit('update:modelValue', id)
      context.emit('setCurrency', id)
    }

    return {
      currencies,
      selectCurrency,
    }
  }
})
</script>

<style scoped>
</style>
