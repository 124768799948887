<template lang="pug">
.cost-wrapper
  .costs
    .cost-range__wrapper
      cost-range(
        v-model:from="costModel.costFrom"
        v-model:to="costModel.costTo"
        :min="costLimit?.costFrom || undefined"
        :max="costLimit?.costTo || undefined"
        label="Начальная цена"
      )
      ui-checkbox(
        v-if="hasAdvancedFilter"
        disabled
        v-model="costLimit.uncertainCost"
        label="Искать с неопределенной ценой"
        :id="'uncertain_cost' + id"
      )
      ui-checkbox(
        v-else
        v-model="costModel.uncertainCost"
        label="Искать с неопределенной ценой"
        :id="'uncertain_cost' + id"
      )
    .cost-range__wrapper
      cost-range(
        label="Обеспечение заявки"
        v-model:from="costAppModel.costFrom"
        v-model:to="costAppModel.costTo"
        :min="costAppLimit?.costFrom || undefined"
        :max="costAppLimit?.costTo || undefined"
      )
      .uncertain_cost_wrapper
        ui-checkbox(
          disabled
          v-if="hasAdvancedFilter"
          v-model="costAppLimit.uncertainCost"
          label="Искать с неопределенной ценой"
          :id="'uncertain_cost_app' + id"
        )
        ui-checkbox(
          v-else
          v-model="costAppModel.uncertainCost"
          label="Искать с неопределенной ценой"
          :id="'uncertain_cost_app' + id"
        )

  .cost_currency
    currency-selector(v-if="hasAdvancedFilter" disabled v-model="costLimit.currency")
    currency-selector(v-else v-model="costModel.currency" @set-currency="setCurrency")
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useVModel } from "@vueuse/core";

import CostRange from "@/components/ui/form/input/CostRange.vue"
import CurrencySelector from "@/components/selector/CurrencySelector.vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";

import type { PropType } from "vue";
import type { CostFilterI } from "@/utils/getters/defaultFilters";
import useManualsStore from "@/stores/manuals/useManualsStore";

export default defineComponent({
  name: "PriceAndPaymentTerms",
  components: {
    UiCheckbox,
    CostRange,
    CurrencySelector,
  },
  props: {
    id: {
      type: String,
      default: 'onSearchForm',
    },
    cost: {
      type: Object as PropType<CostFilterI>,
      default: () => ({}),
    },
    costApp: {
      type: Object as PropType<CostFilterI>,
      default: () => ({}),
    },
    costLimit: {
      type: Object as PropType<CostFilterI>,
      default: () => ({}),
    },
    costAppLimit: {
      type: Object as PropType<CostFilterI>,
      default: () => ({}),
    },
  },
  emits: [
    "costFrom",
    "costTo",
    "update:cost",
    "update:costApp",
  ],
  setup(props, { emit }) {

    const costModel = useVModel(props, 'cost', emit);
    const costAppModel = useVModel(props, 'costApp', emit);

    const manualsStore = useManualsStore()
    const selectedCurrency = computed(() => manualsStore.currencies.find(x => x.id === costModel.value.currency))

    const hasAdvancedFilter = computed(() => !!(props.costLimit?.costFrom || props.costLimit?.costTo || props.costAppLimit?.costFrom || props.costAppLimit?.costTo))

    function setCurrency(id: number | null) {
      costModel.value.currency = id;
      costAppModel.value.currency = id;
    }

    return {
      costModel,
      costAppModel,
      selectedCurrency,
      hasAdvancedFilter,
      setCurrency,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/paddings';

.cost-wrapper {
  display: flex;
  flex-flow: column;
  gap: 8px;

  .cost_currency {
    display: flex;
    flex-flow: column;
    gap: 8px;
    width: 100%;
  }

  .costs {
    display: flex;
    flex-flow: column;
    gap: 8px;
  }

  .cost-range__wrapper {
    @include column_with_space(8px);
  }
}
</style>
