<template lang="pug">
form-input-container(
  :label="label"
  :message="message"
  :message-type="messageType"
)
  .cost_input__form
    cost-input(prefix="От" :placeholder="min?.toString()" v-model="fromModel" @blur:modelValue="prepareCost('from', $event)" :min="min" :max="max")
    cost-input(prefix="До" :placeholder="max?.toString()" v-model="toModel" @blur:modelValue="prepareCost('to', $event)" :min="min" :max="max")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "@vueuse/core";
import type { PropType } from "vue";

import CostInput from "@/components/ui/form/input/CostInput.vue";
import FormInputContainer from "@/components/ui/form/container/FormInputContainer.vue";

export default defineComponent({
  name: "CostRange",
  components: {
    CostInput,
    FormInputContainer,
  },
  props: {
    hint: {
      type: String,
      default: '',
    },
    from: {
      type: Number as PropType<number|null>,
      default: null,
    },
    to: {
      type: Number as PropType<number|null>,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    messageType: {
      type: String as PropType<'error'|'info'>,
      default: 'info',
    },
    min: {
      type: Number,
      default: undefined,
    },
    max: {
      type: Number,
      default: undefined,
    },
  },
  emits: [
    'update:to',
    'update:from',
  ],
  setup(props, { emit }) {

    const fromModel = useVModel(props, 'from', emit);
    const toModel = useVModel(props, 'to', emit);

    // если мы меняем нижнюю границу стоимости
    // И верхняя не равна null
    // И если newCost больше 'до/to', то ставим 'от/from' равное 'до/to'

    // если мы меняем верхнюю границу стоимости
    // И если newCost больше 'до/to', то ставим 'от/from' равное 'до/to'

    function prepareCost(mode: 'from'|'to', newCost: number|null) {
      if (mode === 'from' && (newCost === null || (props.to === null || newCost <= props.to))) {
        emit('update:from', newCost)
      } else if (mode === 'to' && (newCost === null || (props.from === null || props.from <= newCost))) {
        emit('update:to', newCost)
      } else {
        emit('update:to', newCost)
        emit('update:from', newCost)
      }
    }

    return { fromModel, toModel, prepareCost };
  }
});
</script>

<style scoped lang="scss">

.cost_input__form {
  display: flex;
  flex-flow: row;
  gap: 8px;
}

</style>
