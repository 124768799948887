<template lang="pug">
.tag(@click.stop :class="disabled && 'disabled'")
  .label
    textarea.edit-input(
      v-if="!disabled"
      ref="refTextarea"
      :value="modelValue"
      :style="`width: ${ width }px; height: ${ height }px;`"
      @input="onInput"
      @keydown.enter.prevent="unFocusInput"
      @focusout="$emit('focus:out')"
    )
    span.edit-text(ref="el" :class="disabled && 'active'") {{ modelValue }}
  ui-icon(
    v-if="!disabled"
    clickable
    :size="14"
    :icon="UiIconNames.Icon_Close"
    @click.stop="$emit('close')"
  )
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useElementSize } from "@vueuse/core";
import UiIcon from "~/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "WritableTag",
  components: {
    UiIcon,
  },
  emits: [
    'update:modelValue',
    'focus:out',
    'focus:outer',
    'close',
  ],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const el = ref(null)
    const { width, height } = useElementSize(el)

    const refTextarea = ref();

    function unFocusInput() {
      if (refTextarea.value) refTextarea.value?.blur()
      context.emit('focus:outer')
    }

    function onInput(e: any) {
      const value = e.target.value.replace( /  +/g, ' ')
      e.target.value = value
      context.emit('update:modelValue', value)
    }

    return {
      el,
      width,
      height,
      refTextarea,
      onInput,
      unFocusInput,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/elements/tag';

.tag {
  max-width: 100%;

  &.disabled {
    color: #909399;
    background-color: #f4f4f5;
    border-color: #e9e9eb;
  }
}

.label {
  position: relative;
  display: flex;
  max-width: 100%;
}

.edit-text:not(.active) {
  visibility: hidden;
}

textarea {
  position: absolute;
  top: 0;
  left: 0;
  resize: none;
  font-size: 13px;
  line-height: 20px;
  padding: 0;
  margin: 0;
  background-color: inherit;
  border: none;
  outline: none;
  overflow: hidden;
  min-height: 20px;
}

span {
  font-size: 13px;
  line-height: 20px;
  min-height: 20px;
}
</style>
