<template lang="pug">
#tag-editor(:class="disabled && '_disabled'" @click="focusInput")
  template(v-if="writable")
    writable-tag(
      v-for="(tag, index) of tags"
      :key="writable ? index : tag['id']"
      :disabled="disabled"
      :class="customClass(tag)"
      v-model="tags[index]"
      @close="onDeleteTag(tag, index)"
      @focus:out="onFocusOut(tag, index)"
      @focus:outer="focusInput"
    )
  template(v-else)
    readable-tag(
      v-for="tag of tags"
      :key="tag['id']"
      :tag="tag"
      :label="label"
      :class="customClass(tag)"
      @close="onDeleteTag(tag)"
    )
      template(#tag-label="{ tag: t, label: l }")
        slot(name="tag-label" :tag="t" :label="l")

  span.placeholder(v-if="!writable && !modelValue?.length && placeholder") {{ placeholder }}
  input.tag-input(
    v-if="writable"
    ref="tagInput"
    v-model="newTag"
    type="text"
    :disabled="disabled"
    :placeholder="modelValue && modelValue.length ? '' : placeholder"
    @input="onPress"
    @keyup.enter="addNewTag('enter')"
    @keyup.space="addNewTag('space')"
    @keydown.delete="onDeleteClick()"
    @blur="addNewTag('all')"
  )
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useVModel } from "@vueuse/core";
import { deleteTagRef } from "@/utils/searchForm/searchFormWorker";

import type { TagI } from "@/stores/manuals/ManualsInterface";
import type { PropType } from "vue";

import WritableTag from "~/components/tagInput/WritableTag.vue";
import ReadableTag from "~/components/tagInput/ReadableTag.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "TagInput",
  components: {
    ReadableTag,
    WritableTag,
  },
  emits: [
    "delete",
    "on:enter",
    "update:modelValue",
  ],
  props: {
    writable: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String as PropType<'space'|'enter'>,
      default: 'enter',
    },
    modelValue: {
      type: Array as PropType<string[]|TagI[]>,
      default: () => ([]),
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: "title",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, context) {

    const tagInput = ref(null);
    const newTag = ref("");

    const tags = useVModel(props, 'modelValue', context.emit);

    function focusInput() {
      if (tagInput.value) {
        (tagInput.value! as HTMLFormElement).focus();
      }
    }

    function onDeleteClick() {
      if (newTag.value === '' && tags.value.length > 0) tags.value?.splice(tags.value.length - 1, 1)
    }

    function onDeleteTag(tag: any, index: number) {
      if (props.disabled) return;
      if (props.writable) {
        if (index !== -1) tags.value?.splice(index, 1)
      } else {
        deleteTagRef(tags, [tag.id])
        context.emit('delete', tag)
      }
    }

    function onFocusOut(tag: any, index: number) {
      if (!tag) onDeleteTag(tag, index)
    }

    function addNewTag(m: 'space'|'enter'|'all') {
      if (props.disabled) return;
      if (props.mode !== 'space' && m === 'space') return;

      let result = tags.value

      if (newTag.value.trim() !== "") {
        let split = newTag.value.split(/[,;]+/).map(s => s.trim()).filter(Boolean)
        if (split.length) {
          result = [...new Set([...tags.value, ...split])]
          tags.value = result
        }

        newTag.value = "";
      }

      if (m === 'enter') context.emit('on:enter', result)
    }

    function onPress() {
      if (newTag.value.includes(',') || newTag.value.includes(';')) addNewTag('all')
      return
    }

    return {
      tags,
      newTag,
      tagInput,
      onFocusOut,
      onPress,
      addNewTag,
      focusInput,
      onDeleteTag,
      onDeleteClick,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/elements/tag';

#tag-editor {
  padding: 7px 10px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: auto;

  border: 1px solid var(--input-border-color);
  box-sizing: border-box;
  border-radius: 4px;
  height: 184px;

  .tag-input {
    outline: none;
    border: none;
    font-size: 14px;
    padding: 4px;
    flex-grow: 1;

    &:disabled {
      background-color: transparent;
    }
  }

  .placeholder {
    font-size: 14px;
    padding: 4px;
    color: var(--main-placeholder-color);
  }

  &._disabled {
    color: #a8abb2;
    border-color: #e4e7ed;
    background-color: #fcfcfc;
    box-shadow: none;
  }
}
</style>
