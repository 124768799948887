<template lang="pug">
.filter-title
  .filter-title__label
    .label(:class="fromFilterPanel && 'filter-label'")
      slot
    slot(name="additional")
  span.filter-title__cleaner(v-if="showClear" :class="disabled && '_disabled'" @click="!disabled && $emit('reset')")
    | {{ clearTitle }}
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CleanableTitle",
  emits: [
    "reset",
  ],
  props: {
    showClear: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearTitle: {
      type: String,
      default: 'очистить',
    },
    label: {
      type: String,
      default: '',
    },
    fromFilterPanel: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
.filter-title {
  display: flex;
  flex-flow: row;
  gap: 8px;
  align-items: baseline;
  margin-bottom: 8px;
}

.filter-title__label {
  display: flex;
  flex-flow: row;
  gap: 4px;

  .label:not(.filter-label) {
    text-transform: uppercase;
    font-weight: var(--search-title__width);
    font-size: var(--search-title__size);
    line-height: var(--search-title__line-height);
  }

  .filter-label {
    color: var(--main-color-black);
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }
}

.filter-title__cleaner {
  color: var(--main-color-blue);
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  margin-left: auto;

  &._disabled {
    color: var(--button__disabled-text);
    cursor: default;
  }
}
</style>
